.fieldset {
  display: grid;
  grid-template-columns: 3em 1fr;
  grid-auto-rows: auto;
  gap: 10px;
  margin: 0;
  composes: fieldset from '../styles/TimeslotsForm.module.css';

  > * + * {
    margin: 0;
  }

  :global(.DayPickerInput-Overlay) {
    margin-top: 1px;
  }

  :global(.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)) {
    background-color: var(--pink-dark);
  }

  :global(.DayPicker-Day--today) {
    color: var(--pink-dark);
  }

  :global(.DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover) {
    background-color: var(--baby-pink);
  }

  @media ($phone-down) {
    :global(.DayPickerInput-OverlayWrapper),
    :global(.DayPickerInput-Overlay),
    :global(.DayPicker),
    :global(.DayPicker-wrapper) {
      width: 100%;
    }

    :global(.DayPicker-NavButton) {
      top: 1.5em;
      right: 2.25em;
    }

    :global(.DayPicker-Month) {
      margin: 2em 2em 0.5em;
    }

    :global(.DayPicker-Day) {
      padding: 0.75em;
    }
  }

  @media (max-width: 375px) {
    :global(.DayPicker-NavButton) {
      top: 1.5em;
      right: 2.25em;
    }

    :global(.DayPicker-Month) {
      margin: 1.75em 1em 0.5em;
    }

    :global(.DayPicker-Day) {
      padding: 0.65em;
    }
  }
}

.dayPickerOverlay {
  margin-top: 2px !important;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 4px;

  > * {
    margin-top: 0;
    margin-left: 0;
  }
}

.servicePill {
  /* It is what it is */
  margin-left: 0 !important;
  font-size: 12px;
}

.inactiveText {
  color: var(--grey-lando) !important;
}

.title {
  align-self: center;
  font-size: 14px;
}

.topAligned {
  align-self: flex-start;
}

.inputBlock {
  display: flex;
  column-gap: spacing(2);
  align-items: center;
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.findClinicButton {
  font-size: 14px;
  border-radius: 4px;
  box-shadow: var(--light-drop-shadow);
  transition: box-shadow 0.2s ease-in-out;

  :hover {
    box-shadow: var(--strong-drop-shadow);
  }
}

.fullWidth {
  grid-column: 1 / -1;
  margin: 0;
}

.leftSmall {
  grid-column: 1 / 2;
}

.rightLarge {
  grid-column: 2 / 3;
}
