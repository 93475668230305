.checkboxes {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: spacing(1);
  margin-bottom: spacing(2);
  text-transform: capitalize;

  > *:not(:first-child) {
    margin-left: spacing(5);
  }

  @media ($phone-down) {
    > *:not(:first-child) {
      margin-left: spacing(3);
    }
  }
}

.checkboxComponentWrapper {
  font-size: 12px;
}

.checkboxLabel {
  padding-left: spacing(0.5);
}

.checkboxWrapper {
  transform: scale(0.8);

  .checkedCheckbox {
    color: var(--black) !important;

    :focus ~ & {
      color: var(--black);
    }
  }

  .emptyCheckbox {
    color: var(--dark);

    :focus ~ & {
      color: var(--black);
    }
  }
}

.fields {
  padding-top: 0;
}

.fieldset {
  padding-top: 0;

  > * + * {
    margin-top: spacing(1.2);
  }
}

.dropdown {
  padding: 0 12px;
  line-height: 40px;
}

.dropdown,
.inputWrapper input,
.inputWrapper select {
  height: 40px !important;
  font-family: var(--fontFamily-sofia);
  font-size: 14px !important;
  background-color: var(--subdued) !important;
  border: none !important;
  border-radius: 4px !important;

  &:focus {
    outline: var(--grey-lando) auto 1px !important;
  }

  .textarea {
    height: auto;
  }
}

.inputIcon {
  margin-top: 1px;
  fill: var(--grey-lando);

  &::before {
    background-color: var(--grey-lando);
  }

  > svg {
    margin-top: 1px;
    transform: scale(0.8) translate(-4px, -5px);
  }
}

.divider {
  margin: spacing(3.5, 0);
}
