.chevron {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  &::before {
    position: absolute;
    top: 1.2rem;
    right: 0;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    content: '';
    background-color: var(--black);
    mask-image: url('../../../icons/expand_more.svg');
    background-size: cover;
    transform: translate(-50%, -50%);
  }
}
