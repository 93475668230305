.block {
  display: block;
  width: 100%;
}

.root {
  position: relative;
  font-size: 16px;

  & > div {
    display: block;

    & > input {
      width: 100%;
      padding: 0.75rem;
      font-size: inherit;
      color: inherit;
      background-color: transparent;
      border: 2px solid var(--black);
      border-radius: 0;

      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.icon {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.error {
  & > div {
    & > input {
      border-color: var(--error-red);
    }
  }

  .icon {
    svg {
      fill: var(--error-red);
    }
  }
}

.errorMsg {
  padding-top: 5px;
  font-size: 0.75rem;
  color: var(--error-red);
}

.disabled {
  & > div {
    & > input {
      color: var(--grey-solo);
    }
  }
}
